<template>
  <nav class="lb-navigation-wrapper">
    <div class="lb-nav-group group" v-for="item in navigationMenu" :class="{'relative' : !item.custom?.isFullWidth}">
      <NuxtLinkLocale
          class="lb-nav-item"
          :to="getLocaleString(item.href, true)"
          @mouseover="activeMenu=[]"
      >
        {{ getLocaleString(item.name) }}
      </NuxtLinkLocale>

      <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
      >
        <div
            class="menu-1-wrapper"
            :class="{'left-1/2 w-full -translate-x-1/2 transform' : item.custom?.isFullWidth}"
            v-if="item?.children.length > 0"
        >
          <div class="absolute bottom-full w-6 right-5 md:left-1/2 md:-translate-x-1/2 overflow-hidden inline-block" v-if="item.custom?.showDropdownCaret">
            <div class="h-3 w-3 bg-white dark:bg-darkgray-600 border dark:border-darkgray-600 rotate-45 transform origin-bottom-left"></div>
          </div>
          <div class="menu-1-inner-wrapper">
            <!-- 1. Sütun -->
            <div>
              <!--  -->
              <NuxtLinkLocale
                  v-for="i in item?.children"
                  class="dropdown--line--1"
                  :class="{'text-secondary' : activeMenu.id === i.id}"
                  :to="getLocaleString(i.href, true)"
                  @mouseover="activeMenu=i, activeSubMenu=[]"
              >
                <span class="menu-icon" aria-hidden="true" v-if="i.custom?.icon" v-html="i.custom.icon"></span>
                {{getLocaleString(i.name)}}
                <ChevronRightIcon class="h-4 w-4 ml-3" v-if="i.children.length > 0" />
              </NuxtLinkLocale>
            </div>

            <!-- 2. Sütun -->
            <div :class="{'border-l border-gray pl-6' : item.children.length > 0}" v-if="activeMenu?.children?.length > 0">
                <NuxtLinkLocale
                    v-for="menu in activeMenu.children"
                    :key="`mcc-${menu.id}`"
                    class="dropdown--line--2"
                    :class="{'text-secondary' : activeSubMenu?.id === menu.id}"
                    :to="getLocaleString(menu.href, true)"
                    @mouseover="activeSubMenu=menu"
                >
                  {{getLocaleString(menu.name)}}
                  <ChevronRightIcon class="h-4 w-4 ml-3" v-if="menu.children.length > 0" />
                </NuxtLinkLocale>
            </div>

            <!-- 3. Sütun -->
            <div :class="{'border-l border-gray px-6' : item.children.length > 0}" v-if="activeSubMenu?.children?.length > 0">
              <ul role="list" class="text-primary-600">
                <li
                    v-for="menu in activeSubMenu.children"
                    :key="`mcc-${menu.id}`"
                    class="flex py-1"
                >
                  <NuxtLinkLocale
                      class="dropdown--line--3"
                      :to="getLocaleString(menu.href, true)"
                  >
                    {{getLocaleString(menu.name)}}
                  </NuxtLinkLocale>
                </li>
              </ul>
            </div>

            <!-- Menü Görseli -->
            <div class="col-span-3 flex justify-end" v-if="item?.custom?.image">
              &nbsp;
              <NuxtImg
                  provider="ipx"
                  :src="getImage(item.custom.image)"
                  class="dropdown--inner-image "
                  :alt="getLocaleString(item.name)"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script setup>
import {ChevronRightIcon} from '@heroicons/vue/20/solid'

const storeConfig = useStoreConfig()
const { navigationMenu, featuredMenu } = storeToRefs(storeConfig)

const activeMenu = useState('activeMenu', () => [])
const activeSubMenu = useState('activeSubMenu', () => [])

</script>

<style lang="scss">
.dropdown--inner-image {
  @apply min-w-full h-full object-contain rounded-md
}

.dropdown--line--1 {
  @apply flex items-center justify-between py-1.5 text-primary hover:text-secondary cursor-pointer whitespace-nowrap font-medium text-base;
}

.dropdown--line--2 {
  @apply flex items-center justify-between py-1.5 text-primary hover:text-secondary cursor-pointer whitespace-nowrap font-medium;
}

.dropdown--line--3 {
  @apply text-primary hover:text-secondary whitespace-nowrap font-medium;
}

.menu-1-wrapper {
  @apply absolute px-10 py-6 hidden group-hover:block bg-white shadow-xl z-10 text-base;
}

.menu-1-inner-wrapper {
  @apply flex gap-8;
}
</style>
